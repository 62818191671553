<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Prestataire <strong>#{{prestataire.id}}</strong></h3>
        </div>
        <div class="card-toolbar">
          <a class="btn btn-icon btn-sm btn-success mr-1" @click="save">
            <i class="icon-md fas fa-save"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Detail</h3>
            </div>
          </div>
          <div class="card-body">
            <b-form>
              <b-form-group label="Nom:">
                <b-form-input v-model="prestataire.nom"></b-form-input>
              </b-form-group>
              <b-form-group label="Siret:">
                <b-form-input v-model="prestataire.info.siret"></b-form-input>
              </b-form-group>
              <b-form-group label="Adresse:">
                <b-form-input v-model="prestataire.info.adresse"></b-form-input>
              </b-form-group>
              <b-form-group label="Ville:">
                <b-form-input v-model="prestataire.info.ville"></b-form-input>
              </b-form-group>
              <b-form-group label="Code postal:">
                <b-form-input v-model="prestataire.info.cp"></b-form-input>
              </b-form-group>
              <b-form-group label="Pays:">
                <b-form-input v-model="prestataire.info.pays"></b-form-input>
              </b-form-group>
              <b-form-group label="Site:">
                <b-form-input v-model="prestataire.info.site"></b-form-input>
              </b-form-group>
              <b-form-group label="Logo:">
                <b-form-input v-model="prestataire.info.logo"></b-form-input>
              </b-form-group>
              <b-form-group label="Status:">
                <select class="form-control" v-model="prestataire.status">
                  <option value=1>actif</option>
                  <option value=0>inactif</option>
                </select>
              </b-form-group>
            </b-form>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-12">
            <div class="card card-custom gutter-b">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">Parametrage</h3>
                </div>
              </div>
              <div class="card-body">
                <b-form-group label="Services">
                  <b-form-checkbox-group  id="checkbox-group-2" v-model="prestataire.service_id" :options="params.services"  name="flavour-2"></b-form-checkbox-group>
                </b-form-group>
                <b-form-group label="Solutions">
                  <b-form-checkbox-group  id="checkbox-group-3" v-model="prestataire.solution_id" :options="params.solutions"  name="flavour-3"></b-form-checkbox-group>
                </b-form-group>
                <div class="form-group">
                  <label>Departements <a class="btn btn-icon btn-sm btn-success mr-1" @click="allDepartements()">Tous</a></label>
                  <multiselect v-model="prestataire.departements" :options="DepartementsOptions" :multiple="true"></multiselect>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card card-custom gutter-b">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">Options</h3>
                </div>
              </div>
              <div class="card-body">
                <b-tabs content-class="mt-3">
                  <b-tab v-for="(l,i) in prestataire.solution_id" :key="i" :title="params.solutions.find(s => s.id == l).nom.fr">
                    <div class="form-check" v-for="(o,w) in prestataire.options.filter(e => e.solution_id == l)" :key="w">
                      <input type="checkbox" class="form-check-input" v-model="o.status">
                      <label class="form-check-label">{{params.options.find(q => q.id == o.option_id).nom.fr}}</label>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card card-custom gutter-b">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">Description</h3>
                </div>
              </div>
              <div class="card-body">
                <b-tabs content-class="mt-3">
                  <b-tab v-for="(l,i) in params.langues" :key="i" :title="l.titre">
                    <b-form-textarea  v-model="prestataire.info.description[l.val]" rows="6"></b-form-textarea>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Grille tarifaire</h3>
            </div>
            <div class="card-toolbar">
              <b-button variant="info" size="sm" class="btn-icon mr-1" v-b-modal.modalKm>KM</b-button>
              <b-button variant="info" size="sm" class="btn-icon mr-1" v-b-modal.modalVolume>M3</b-button>
            </div>
          </div>
          <div class="card-body">
            <b-tabs content-class="mt-3" v-model="tab">
              <b-tab v-for="(grille,i) in prestataire.grille" :key="i" :title="params.solutions.find(s => s.id ==  grille.solution_id).nom.fr">
                <b-row>
                  <b-col>
                    <b-form-group label="Categorie 1">
                      <b-form-input v-model.number="grille.categorie_1" type="number" required placeholder="Majoration en %"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Categorie 2">
                      <b-form-input v-model.number="grille.categorie_2" type="number" required placeholder="Majoration en %"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Categorie 3">
                      <b-form-input v-model.number="grille.categorie_3" type="number" required placeholder="Majoration en %"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="Haute saison debut">
                      <b-form-datepicker v-model="grille.debut"></b-form-datepicker>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Haute saison fin">
                      <b-form-datepicker v-model="grille.fin"></b-form-datepicker>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Majoration">
                      <b-form-input v-model.number="grille.majoration" type="number" required placeholder="Majoration en %"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">
                        <span><i class="fas fa-arrow-down"></i>&nbsp;&nbsp;Kilometrage</span>
                        <span class="ml-5"><i class="fas fa-arrow-right"></i>&nbsp;&nbsp;&nbsp;&nbsp;Volume</span>
                      </th>
                      <th scope="col" v-for="(v,i) in grille.volume" :key="i">{{v.start}} - {{v.end}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(k,w) in grille.km" :key="w">
                      <th scope="row">{{k.start}} - {{k.end}}</th>
                      <td v-for="(kv,q) in k.volumes" :key="q"><b-form-input v-model.number="kv.prix" type="number"></b-form-input></td>
                    </tr>
                  </tbody>
                </table>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modalVolume" title="Ajouter un Volume" ref="modalVolume">
      <b-form>
        <b-form-group label="De:">
          <b-form-input v-model.number="tmpV.start" type="number" required placeholder="0"></b-form-input>
        </b-form-group>
        <b-form-group label="A:">
          <b-form-input v-model.number="tmpV.end" type="number" required placeholder="20"></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right mr-2" @click="addVolume()">
            Sauvegarder
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.modalVolume.hide();">
            Fermer
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="modalKm" title="Ajouter un Kilometrage" ref="modalKm">
      <b-form>
        <b-form-group label="De:">
          <b-form-input v-model.number="tmpK.start" type="number" required placeholder="0"></b-form-input>
        </b-form-group>
        <b-form-group label="A:">
          <b-form-input v-model.number="tmpK.end" type="number" required placeholder="20"></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right mr-2" @click="addKm()">
            Sauvegarder
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.modalKm.hide();">
            Fermer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Multiselect from 'vue-multiselect'
export default {
  data(){
    return {      
      DepartementsOptions: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '2A',
        '2B',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30',
        '31',
        '32',
        '33',
        '34',
        '35',
        '36',
        '37',
        '38',
        '39',
        '40',
        '41',
        '42',
        '43',
        '44',
        '45',
        '46',
        '47',
        '48',
        '49',
        '50',
        '51',
        '52',
        '53',
        '54',
        '55',
        '56',
        '57',
        '58',
        '59',
        '60',
        '61',
        '62',
        '63',
        '64',
        '65',
        '66',
        '67',
        '68',
        '69',
        '70',
        '71',
        '72',
        '73',
        '74',
        '75',
        '76',
        '77',
        '78',
        '79',
        '80',
        '81',
        '82',
        '83',
        '84',
        '85',
        '86',
        '87',
        '88',
        '89',
        '90',
        '91',
        '92',
        '93',
        '94',
        '95'
      ],
      tab:null,
      prestataire:{
        id: null,
        nom: null,
        info: {
          siret: null,
          adresse: null,
          ville: null,
          cp: null,
          pays: null,
          site: null,
          logo: null,
          description: {
            fr:null,
          }
        },
        service_id: [],
        solution_id: [],
        options: [],
        departements:[],
        date_create: null,
        date_update: null,
        grille:[]
      },
      params: {
        services:[],
        solutions:[],
        langues:[],
        options:[]
      },
      tmpV:{
        start:null,
        end:null
      },
      tmpK:{
        start:null,
        end:null
      }
    };
  },
  components: {
    Multiselect
  },
  watch: {
  },
  methods: {
    save(){
      this.$store.back.ajax('/prestataire/update', this.prestataire, (res) => {
        if(res.status === true){
          this.projet = res.data;
          this.$bvToast.toast(`Votre prestataire est bien sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
        }
      });
    },
    addVolume(){
      var the = this;
      var solution = this.prestataire.solution_id[this.tab];
      var grille = the.prestataire.grille.find(g => g.solution_id == solution );
      if(!grille){
        return false;
      }
      grille.km.forEach((item) => {
        item.volumes.push({
          start: parseInt(the.tmpV.start),
          end: parseInt(the.tmpV.end),
          prix:0
        });
      });
      grille.volume.push({
        start: parseInt(the.tmpV.start),
        end: parseInt(the.tmpV.end)
      });
      this.$refs.modalVolume.hide();
    },
    addKm(){
      var the = this;
      var solution = this.prestataire.solution_id[this.tab];
      var grille = the.prestataire.grille.find(g => g.solution_id == solution );
      if(!grille){
        return false;
      }
      var tmp = {
        start: parseInt(this.tmpK.start),
        end: parseInt(this.tmpK.end),
        volumes: []
      };
      grille.volume.forEach((item) => {
        var t = {
          start:item.start,
          end:item.end,
          prix:0
        }
        tmp.volumes.push(t);
      });
      grille.km.push(tmp);
      this.$refs.modalKm.hide();
    },
    majOptions(){
      var opt = JSON.parse(JSON.stringify(this.prestataire.options));
      var options = JSON.parse(JSON.stringify(this.params.options));
      opt = opt.filter(o => o.solution_id == 1);
      opt.forEach((item) => {
        var check = this.params.options.find(e => e.id == item.option_id);
        if(check){
          options = options.filter(o => o.id != item.option_id);
        }
      });
      options.forEach((option) => {
        this.prestataire.solution_id.forEach((param) => {
          this.prestataire.options.push({
            option_id:option.id,
            solution_id:param,
            status:false
          });
        });
      });
    },
    allDepartements(){
      this.prestataire.departements = this.DepartementsOptions;
    }
  },
  computed: {
  },
  beforeMount(){
    this.$store.back.ajax('/parametrage/all', null, (res) => {
      if(res.status === true){
        res.data.services.filter(h => {return h.text = h.nom.fr});
        res.data.services.filter(h => {return h.value = h.id});
        res.data.solutions.filter(h => {return h.text = h.nom.fr});
        res.data.solutions.filter(h => {return h.value = h.id});
        this.params = res.data;
        //  console.log(this.params);
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
    this.$store.back.ajax(`/prestataire/${this.$route.params.id}`, null, (res) => {
      if(res.status === true){
        this.prestataire = res.data;
        /*if(!this.prestataire.departements){
          this.prestataire.departements = [];
        }*/
        this.majOptions();
      }
    });
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
